import React from "react";
import "./Services.css";
import "./subcomponents/service/Service.css";

export const Services = () => {
  return (
    <section id="servicesWrapper">
      <h2 id="servicesLabel">Services</h2>
      <section id="services">
        <section className="servicesContainer">
          <article className="service pointer grow">
            <h3>Cars</h3>
            <ul>
              <li>Teens, adults, refreshers; everyone is welcome!</li>
              <li>
                Training and knowledge tests for class 4, 5, 7
              </li>
              <li>
                Learn in English or Punjabi, whatever works best for you!
              </li>
              <li>You can rent a car from us for your road-test</li>
            </ul>
          </article>
        </section>
        {/* <section className="servicesContainer">
          <article className="service pointer grow">
            <h3>Trucks</h3>
            <ul>
              <li>We provide knowledge tests for class 1 and air brake</li>
              <li>
                Learn in English or Punjabi, whatever works best for you!
              </li>
              <li>
                We provide in-truck lessons for standard and automatic trucks
              </li>
              <li>
                Our students have a high pass rate
              </li>
            </ul>
          </article>
        </section> */}
      </section>
    </section>
  );
};
